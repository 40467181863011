export const useCreateFlowForForm = (flowType: 'login' | 'recovery' | 'verification') => {
  const router = useRouter()
  const oryInstance = useOry()
  const bugsnag = useBugsnag()
  const isLoading = ref<boolean>(false)

  const { kratosFlow, setKratosFlow } = useFlowOry()
  const flow = ref<any>(kratosFlow.value ?? null)

  const createFlow = async () => {
    if (!flow.value) {
      isLoading.value = true
      try {
        let response
        if (flowType === 'login') {
          response = (await oryInstance.createBrowserLoginFlow()).data
        } else if (flowType === 'recovery') {
          response = (await oryInstance.createBrowserRecoveryFlow()).data
        } else if (flowType === 'verification') {
          response = (await oryInstance.createBrowserVerificationFlow()).data
        }

        flow.value = response
        const redirectPath = `/auth/${flowType}?flow=${response?.id}`
        router.replace(redirectPath)
      } catch (err: any) {
        bugsnag.notify(err)
        if (err?.response?.data?.error?.code === 400) {
          router.replace('/home')
        } else {
          throw createError({
            statusCode: 500,
            message: `Unable to create ${flowType} flow`,
            statusMessage: err?.response?.data?.error?.message ?? 'Unknown error',
          })
        }
      } finally {
        isLoading.value = false
      }
    }
  }

  onMounted(async() => {
    await createFlow()
  })

  const fields = computed(() => {
    if (flow.value) {
      return flow.value?.ui?.nodes ?? []
    }
    return []
  })

  const messages = computed(() => {
    if (flow.value) {
      return flow.value?.ui?.messages ?? []
    }
    return []
  })

  const goToRoute = (path: string) => {
    setKratosFlow(null)
    router.push(path)
  }

  return {
    flow,
    isLoading,
    fields,
    messages,
    goToRoute
  }
}