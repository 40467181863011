<script lang="ts" setup>

</script>
<template>
  <div>
    <div class="flex flex-col space-y-5 my-5">
      <div class="space-y-2">
        <USkeleton class="h-4 w-[50px]" />
        <USkeleton class="h-8 w-full" />
      </div>
      <div class="space-y-2">
        <USkeleton class="h-4 w-[50px]" />
        <USkeleton class="h-8 w-full" />
      </div>
      <div>
        <USkeleton class="h-10 w-full" />
      </div>
    </div>
  </div>
</template>
